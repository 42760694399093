import React from "react"
import {Link} from 'gatsby';
import Layout from "../../components/trafficsigns/layout"
import seologo from "../../images/trafficsigns/seo_logo.png"
import Seo from "../../components/SEO"

function TrafficWizardPrivacyPage(props) {

    const seoImage = {
		src: seologo,
		height: 256,
		width: 256,
		alt: "Traffic Signs Wizard logo",
    };

    const appStoreCampaignUrl = "https://apps.apple.com/app/apple-store/id1543287536?pt=121290356&ct=malaudhome&mt=8"

    return (
    <Layout>
        <Seo
            siteTitle={'Traffic Signs Wizard Privacy Policy'}
            seoTitle='Traffic Signs Wizard Privacy Policy'
            path={props.location.pathname}
            metaImage={seoImage}
            icon={seologo}
            description='Traffic Signs Wizard - Privacy Policy'
            keywords={["Traffic Signs Wizard", "Traffic Signs Wizard Privacy Policy"]}
            datePublished="03-21-2021"
            dateModified="11-01-2022"
        />
        
        <div className={"container"} style={{textAlign: 'left'}}>
            <div className={"content"}>
                <img style ={{maxWidth: "10%"}} alt={"Traffic Signs Wizard logo"} src={seologo}/>

                <div className={"title"}>
                    <h1 style={{textDecoration: 'None', color: '#0398F4'}}>Traffic Signs Wizard for iOS</h1>
                    <h2>Privacy Policy</h2>
                </div>

                <div>
                <h6 style={{textAlign: 'left'}}>
                    Last Updated: Nov 01, 2022
                </h6>
                </div>

                <p>
                    We value your privacy and believe that everyone has the right to protect their personal data.
               </p>
               <p>
                    The following statements describe the privacy policy for <a href={appStoreCampaignUrl} target={"_blank"} rel="noreferrer" style={{textDecoration: 'None', color: '#0398F4'}} title={"Traffic Signs Wizard for iOS"}>Traffic Signs Wizard for iOS</a> app. To read about the privacy policy for this website, please visit <Link to="/privacy" style={{textDecoration: 'None', color: '#0398F4'}}>this link</Link>.
                </p>

                <h3>Data we don't collect</h3>

                <p style={{padding: 0}}>
                    <span style={{fontWeight: 'bold'}}>Personally Identifiable Data: </span> Any personally identifiable data is not collected, such as user name, email, phone number, address, contacts etc.
                </p>

                <br />

                <h3>Data we collect and how it's used</h3>

                <p>
                    <span style={{fontWeight: 'bold'}}>Location Data: </span>To comply with GDPR in Europe, and CCPA in California, device's IP address may be used to determine the general location of the device. This information is used to comply with the consumer privacy laws, such as GDPR in EEA/UK, and CCPA in California. This data may also be shared with Google, and other third parties to display non-personalized advertisements within the app. The location data is not used for tracking purposes.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Performance Data: </span>Certain performance data may be collected, such as app launch time, hang rate, energy usage, etc, to evaluate user behavior, and understand the effectiveness of existing product features. The performance data may also be used to display non-personalized advertisements within the app. The performance data is not used for tracking purposes.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Diagnostic Data: </span>Diagnostic data, such as crash logs, may be used to diagnose problems and improve the app. Diagnostic data may also be used to display non-personalized advertisements within the app. The diagnostic data is not used for tracking purposes.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Identifiers: </span>App based device identifiers are used for app functionality and to verify any in-app purchase transactions. Device identifiers may also be used for analytics, and to display non-personalized advertisements within the app. These identifiers are not used tracking purposes.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Advertisement Data: </span>Advertisement data, such as advertisements displayed, or seen by the user, within the app, may be used for analytics purposes, and to improve advertising performance.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Other Diagnostic Data: </span>When you have enabled sharing anonymous diagnostic information on your devices, Apple may share any app related diagnostic information, crash logs, etc. with us. This information does not contain any personal data, and is only used to diagnose problems and improve app performance.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Support Data: </span>When you contact support, you provide your email, name and other information that may be relevant to your support query. This information will be stored on our servers and for the sole purpose of answering your support request and for legal & administrative purposes.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>GDPR: </span>Traffic Signs Wizard fully complies with GDPR and EU consent policy. Users in Europe Economic Area (EEA) and UK, are required to provide consent to opt-in to any data collection and usage. Traffic Signs Wizard uses Google's IAB compliant, User Messaging Platform, to gather user consent where required. When consent is provided, non-personalized advertisements may be displayed according to the given consent. Users may make changes or withdraw the consent at any time, by using the consent form link, in the app preferences.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>CCPA: </span>We do NOT sell your personal information, and personalized advertisements are not displayed. To comply with CCPA in California, restricted data processing has been enabled. With restricted data processing, Google will restrict how it uses certain unique identifiers and other data processed in the provision of services. For example, Google will not create or update profiles for ads personalization or use existing profiles to serve personalized ads relating to data to which restricted data processing applies.
                </p>

                <p>
                    To know more about how Google uses this data, visit <a href="https://policies.google.com/technologies/partner-sites" target={"_blank"} rel="noreferrer" style={{textDecoration: 'None', color: '#0398F4'}}>https://policies.google.com/technologies/partner-sites</a>
                </p>

                <br />
                <hr style={{width: '30%'}}></hr>
                <br />


                {/* <h3>Consent</h3> */}
                <p>
                    By using our website, you hereby consent to our website <Link to="/privacy" style={{textDecoration: 'None', color: '#0398F4'}}>Privacy Policy</Link>.
                    If you have additional questions or require more information, please <Link to="/contact" style={{textDecoration: 'None', color: '#0398F4'}}>contact us</Link>.
                </p>

            </div>
        </div>
    </Layout>
    )
}

export default TrafficWizardPrivacyPage
